<template>
    <div class="app-sidebar sidebar-shadow">
        <div class="app-header__logo">
            <div class="logo-src"></div>
            <div class="header__pane ml-auto">
                <div>
                    <button type="button" class="hamburger close-sidebar-btn hamburger--elastic" data-class="closed-sidebar">
                        <span class="hamburger-box">
                            <span class="hamburger-inner"></span>
                        </span>
                    </button>
                </div>
            </div>
        </div>
        <div class="app-header__mobile-menu">
            <div>
                <button type="button" class="hamburger hamburger--elastic mobile-toggle-nav">
                    <span class="hamburger-box">
                        <span class="hamburger-inner"></span>
                    </span>
                </button>
            </div>
        </div>
        <div class="app-header__menu">
            <span>
                <button type="button" class="btn-icon btn-icon-only btn btn-primary btn-sm mobile-toggle-header-nav">
                    <span class="btn-icon-wrapper">
                        <i class="fa fa-ellipsis-v fa-w-6"></i>
                    </span>
                </button>
            </span>
        </div>
        <div class="scrollbar-sidebar ps ps--active-y">
            <div class="app-sidebar__inner">
                <ul class="vertical-nav-menu metismenu">
                    <li class="app-sidebar__heading">
                        <div class="row mt-2">
                          <div class="col-md-3 text-center pointer-courser">
                            <span class="unReadMsg" v-if="unReadMessageCount>0 & isAdmin=='T'">{{unReadMessageCount}}</span>
                          <img v-if="ImageExist(imageFile)" :src="imageFile" class="user-thumbnail" @click="uploadUserImage()" alt="Forest">
                          <img v-else src="https://www.w3schools.com/howto/img_avatar.png" class="user-thumbnail" @click="activeMenu('settings')" alt="Forest">
                          <input type="file" id="profileImage" style="display: none;" accept="image/*" @change="onChange"/>
                          </div>
                          <div class="col-md-9 text-left mt-3"><h4 class="userName" style="font-size: 1.2vw;">{{name}}</h4>
                          <h5 class="userType">{{user_type}}
                            <span v-if="isCoaching!='No' & checkCoachingExpiry()">Coaching</span>
                          </h5>
                          </div>
                        </div>
                    </li>
                    <hr>
                    <li class="app-sidebar__heading">Home</li>
                    <li :id="menu.link" v-for="(menu, index) in firstMenuLists" :key="index" :class="(menu.title == 'Others' | menu.title == 'Premium' | menu.title == 'Coaching')?'app-sidebar__heading':''">
                        <template v-if="menu.title == 'Others' | menu.title == 'Premium' | menu.title == 'Coaching'">
                            {{ menu.title }}
                        </template>
                        <template v-else-if="menu.title == 'Users Plan Notification'">
                             <a v-if="menu.type=='admin' & isAdmin=='T'" :class="[menu.link] == active ? 'mm-active':'' " @click="activeMenu(menu.link)">
                                <i class="metismenu-icon" :class="[menu.icon]"></i>
                                {{ menu.title }}
                            <span class="unReadNotification" v-if="unReadUserChangeNotificationsCount>0 & isAdmin=='T'">{{unReadUserChangeNotificationsCount}}</span>

                            </a>
                        </template>
                        <template v-else>
                            <a v-if="menu.type=='all'" :class="[menu.link] == active ? 'mm-active':'' " @click="activeMenu(menu.link)">
                                <i class="metismenu-icon" :class="[menu.icon]"></i>
                                {{ menu.title }}
                            </a>
                            <a v-if="menu.type=='admin' & isAdmin=='T'" :class="[menu.link] == active ? 'mm-active':'' " @click="activeMenu(menu.link)">
                                <i class="metismenu-icon" :class="[menu.icon]"></i>
                                {{ menu.title }}
                            </a>
                            <!-- v-if="(menu.type=='premium' & isCoaching!='No' & checkCoachingExpiry()) | (menu.type=='premium' & isAdmin=='T') | (menu.type=='premium' & user_type!='Free')" -->
                            <a class="premiumTag" v-if="menu.type=='premium'" :class="[menu.link] == active ? 'mm-active':'' " @click="activeMenu(menu.link)">
                                <i class="metismenu-icon" :class="[menu.icon]"></i>
                                {{ menu.title }}
                                <span>PREMIUM</span>
                            </a>
                            <a v-if="(menu.type=='coaching' & isCoaching!='No' & checkCoachingExpiry())" :class="[menu.link] == active ? 'mm-active':'' " @click="activeMenu(menu.link)">
                                <i class="metismenu-icon" :class="[menu.icon]"></i>
                                {{ menu.title }}
                            </a>
                        </template>
                    </li>
                    <li><hr/></li>
                    <li><a href="https://pteclasses.com/privacypolicy/" target="_blank"><i class="metismenu-icon pe-7s-key" ></i> Privacy Policy</a></li>
                    <li><a href="https://pteclasses.com/terms-conditions/" target="_blank"><i class="metismenu-icon pe-7s-albums"></i> Terms & Conditions</a></li>
                </ul>
            </div>
        <div class="ps__rail-x" style="left: 0px; bottom: 0px;"><div class="ps__thumb-x" tabindex="0" style="left: 0px; width: 0px;"></div></div><div class="ps__rail-y" style="top: 0px; height: 662px; right: 0px;"><div class="ps__thumb-y" tabindex="0" style="top: 0px; height: 646px;"></div></div></div>
    </div>
</template>

<script>
import $ from 'jquery'
import { mapActions } from 'vuex'
import {bus} from '../../main'
export default {
  props:['selectedMenu'],
  data () {
    return {
      name:'',
      user_type:'',
      isAdmin:'N',
      isCoaching:'No',
      active: this.selectedMenu,
      firstMenuLists: [
        {
          title: 'Dashboard',
          icon: 'pe-7s-home',
          link: 'dashboard',
          type:'all'
        },
        {
          title: 'Learning Hub',
          icon: 'fa fa-university',
          link: 'study-center',
          type:'all'
        },
        // {
        //   title: 'Mock Test',
        //   icon: 'pe-7s-file',
        //   link: 'free-mock-tests',
        //   type:'all'
        // },
        {
          title: 'Vocabulary',
          icon: 'pe-7s-notebook',
          link: 'vocab-books',
          type:'all'
        },
        {
          title: 'Settings',
          icon: 'pe-7s-config',
          link: 'settings',
          type:'all'
        },
        {
          title: 'Referral Users',
          icon: 'pe-7s-share',
          link: 'referral',
          type:'all'
        },
        // {
        //   title: 'Premium'
        // },
         {
          title: 'Mock Test',
          icon: 'pe-7s-news-paper',
          link: 'scored-mock-tests',
          type:'premium'
        },
         {
           title: 'Section Mock Test',
           icon: 'pe-7s-network',
           link: 'ModuleMockTest',
           type:'premium'
         },
        {
          title: 'Important Videos',
          icon: 'pe-7s-video',
          link: 'helpingVideos',
          type:'premium'
        },
        {
          title: 'Coaching'
        },
        {
          title: 'Meetings',
          icon: 'pe-7s-plugin',
          link: 'meetings',
          type:'coaching'
        },
        // {
        //   title: 'Coaching Plans',
        //   icon: 'pe-7s-medal',
        //   link: 'coachingPlans',
        //   type:'all'
        // },
        
        {
          title: 'Create Meetings',
          icon: 'pe-7s-timer',
          link: 'createMeetings',
          type:'admin'
        },
        // {
        //   title: 'Listening',
        //   icon: 'pe-7s-headphones',
        //   link: 'listening',
        //   type:'coaching'
        // },
        // {
        //   title: 'Presentation',
        //   icon: 'pe-7s-network',
        //   link: 'presentation',
        //   type:'premium'
        // },
        {
          title: 'Templates',
          icon: 'pe-7s-note',
          link: 'writing',
          type:'all'
        },
        {
          title: 'PTE 90/90',
          icon: 'pe-7s-notebook',
          link: 'premiumTemplates',
          type:'premium'
        },
        {
          title: 'Others'
        },
        {
          title: 'Help (Q&A)',
          icon: 'pe-7s-help1',
          link: 'help',
          type:'all'
        },
        {
          title: 'Contact Us',
          icon: 'pe-7s-chat',
          link: 'contact-us',
          type:'all'
        },
        {
          title: 'Register Users',
          icon: 'fa fa-users',
          link: 'register-user',
          type:'admin'
        },
        {
          title: 'Referral Users List',
          icon: 'fa fa-connectdevelop',
          link: 'referralpayments',
          type:'admin'
        },
        {
          title: 'Users Messages',
          icon: 'pe-7s-albums',
          link: 'userMessages',
          type:'admin'
        },
        {
          title: 'Why Premium Package',
          icon: 'pe-7s-science',
          link: 'whyPremiumPackage',
          type:'admin'
        },
        {
          title: 'Users Plan Notification',
          icon: 'pe-7s-note2',
          link: 'userChangeNotification',
          type:'admin'
        },
        {
          title: 'Result Card',
          icon: 'fa fa-magic',
          link: 'resultCard',
          type:'admin'
        },
        {
          title: 'Video Comments',
          icon: 'pe-7s-comment',
          link: 'userCommentss',
          type:'admin'
        },
        {
          title: 'Log Out',
          icon: 'pe-7s-rocket',
          link: 'log-out',
          type:'all'
        }
      ],
      imageFile:'userImage/userImage5.jpg',
      user:null,
      unReadMessageCount:0,
      unReadUserChangeNotificationsCount:0
    }
  },
  methods: {
    ...mapActions({
            fileUpload:'speakmodule/uploadFile',
        }),
    activeMenu (menu) {
      if(menu=='log-out'){
       return this.signOut()
      }
      if(this.checkCoachingExpiry() & this.isAdmin !='T' & this.isCoaching=='No' & (menu=='listening' | menu=='presentation')){
        return this.$alert('You need to purchase premium plan to access helpful premium material.','Purchase premium plan')
      }
      this.active = menu
      this.$parent.currentTab=menu
      this.$parent.predictTab=false
    },
    checkCoachingExpiry(){
      var checkCoaching=true;
      //console.log(this.user.coaching_payment_type)
      if(this.user.coachingExpiry!=null & this.user.coachingExpiry!='' & this.user.coaching_payment_type=='Cash'){
        var expiryDate = new Date(this.user.coachingExpiry);
        var currentDate=new Date();
        //console.log('IN COACHING EXPIRY')
        //console.log(expiryDate)
        checkCoaching = (expiryDate>=currentDate)? true: false;
      }
      return checkCoaching
    },
    changeMenuItem(selectedItem){
      this.active=selectedItem
    },
    recheckUnreadMessageCount(newCount)
    {
      this.unReadMessageCount=newCount
       //console.log("Check Unread Message Count = "+this.unReadMessageCount)
    },
    recheckUnreadChangeNotificationsCount(newCount)
    {
      this.unReadUserChangeNotificationsCount=newCount
       //console.log("Check Unread Message Count = "+this.unReadUserChangeNotificationsCount)
    },
    signOut(){
      this.$parent.$parent.externalLogout()
        },
    ImageExist(url) 
      {
        var img = new Image();
        img.src = url;
        return img.height != 0;
      },
      uploadUserImage(){
        //$("input[id='profileImage']").click();
        //console.log($("input[id='profileImage']"))
      },
      onChange(e) {
            this.imageFile = e.target.files
            var data={
                fileName:'userImage--',
                files:this.imageFile
            }
            data.fileName+=this.user.user_id
            this.fileUpload(data)
        },
  },
  created(){
     this.user=this.$ls.get('user')
     //debugger
     console.log('usertype > > ',this.user.user_type)
     if(this.user!=null){
       
       this.isCoaching=(this.user.coaching==null)?'No':this.user.coaching;
       //console.log('IN Side MENU')
       //console.log(this.isCoaching)
       this.name=this.user.first_name
        this.user_type=this.user.user_type
        this.isAdmin=this.user.is_admin
       
        this.$forceUpdate()
     }
     bus.$on('menuClick', this.activeMenu);
  }

}
</script>
<style scoped>
.premiumTag span{
  font-size: 10px;
    color: #e00303;
    right: 10px;
    position: absolute;
    font-weight: 800;
}
.userType
{
color: #f23276;
    /* font-size: .8rem; */
    font-size: .8rem;
    /* float: right; */
    padding-top: 7px;
    font-weight: 700;
}
.userType span{
  display: block!important;
}
.unReadMsg
{
  background: #f23276;
    color: #fff;
    width: 22px;
    border-radius: 50%;
    height: 22px;
    display: inline-block;
    position: absolute;
}
.unReadNotification
{
      background: #f23276;
    color: #fff;
    width: 22px;
    border-radius: 50%;
    /* height: 22px; */
    display: inline-block;
    position: absolute;
    left: 0px;
    text-align: center;
    line-height: 20px;
}
.userName
{
  font-weight: 700;
  color: #001848;
}
</style>